import React from 'react';
import Intl from 'intl';
import 'intl/locale-data/jsonp/sv-SE';
import PropTypes from 'prop-types';
import StatisticColumn from './StatisticColumn';

const propTypes = {
    statistic: PropTypes.object.isRequired
};

const svNumberFormat = new Intl.NumberFormat('sv-SE', { style: 'decimal' });
const numberFormat = number => (number ? svNumberFormat.format(number) : undefined);

const total = (key, data) => {
    if (typeof data !== 'undefined') {
        return Object.keys(data).reduce((acc, marketplace) => {
            if (typeof data[marketplace][key] !== 'undefined') {
                if (typeof acc === 'number') {
                    return acc + data[marketplace][key];
                }

                return data[marketplace][key];
            }
            return acc;
        }, undefined);
    }

    return undefined;
};

const StatisticsRow = React.memo(props => {
    const { statistic } = props;

    const current = {
        views: total('views', statistic.current),
        messages: total('messages', statistic.current),
        phoneContacts: total('phone_contacts', statistic.current)
    };

    const comparison = statistic.comparison
        ? {
              views: total('views', statistic.comparison),
              messages: total('messages', statistic.comparison),
              phoneContacts: total('phone_contacts', statistic.comparison)
          }
        : {};

    return (
        <tr>
            <td>{statistic.name}</td>
            <td>{statistic.city.name}</td>
            <td className="text-right">{numberFormat(current.views)}</td>
            <td className="text-left">
                <StatisticColumn current={current.views} comparison={comparison.views} />
            </td>
            <td className="text-right">{numberFormat(current.messages)}</td>
            <td className="text-left">
                <StatisticColumn current={current.messages} comparison={comparison.messages} />
            </td>
            <td className="text-right">{numberFormat(current.phoneContacts)}</td>
            <td className="text-left">
                <StatisticColumn current={current.phoneContacts} comparison={comparison.phoneContacts} />
            </td>
        </tr>
    );
});

StatisticsRow.propTypes = propTypes;

export default StatisticsRow;
