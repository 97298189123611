import React from 'react';
import PropTypes from 'prop-types';
import { Table } from '@bytbil/bootstrap-react';
import StatisticsRow from './StatisticsRow';

const propTypes = {
    statistics: PropTypes.array.isRequired,
    onTableHeadClick: PropTypes.func
};

const defaultProps = {
    onTableHeadClick: Function.prototype
};

const StatisticsTable = React.memo(props => {
    const { statistics, onTableHeadClick } = props;

    return (
        <Table className="statistics-table border-bottom-1" striped responsive bordered>
            <thead>
                <tr>
                    <th className="statistics-table-name" onClick={() => onTableHeadClick('name')}>
                        Anläggning
                    </th>
                    <th className="statistics-table-city" onClick={() => onTableHeadClick('city')}>
                        Ort
                    </th>
                    <th className="statistics-table-views" colSpan="2" onClick={() => onTableHeadClick('views')}>
                        <img className="mr-2" height="16" src="/img/statistics/eye.svg" alt="Annonsvisningar" />
                        Annonsvisningar
                    </th>
                    <th className="statistics-table-messages" colSpan="2" onClick={() => onTableHeadClick('messages')}>
                        <img className="mr-2" height="16" src="/img/statistics/mail.svg" alt="Epost/SMS" />
                        Epost/SMS
                    </th>
                    <th
                        className="statistics-table-phone-contacts"
                        onClick={() => onTableHeadClick('phoneContacts')}
                        colSpan="2"
                    >
                        <img className="mr-2" height="16" src="/img/statistics/phone.svg" alt="Klick på tel.nr" />
                        Klick på tel.nr
                    </th>
                </tr>
            </thead>
            <tbody>
                {statistics.map(statistic => (
                    <StatisticsRow key={`statistics-row-${Math.random() * 100}`} statistic={statistic} />
                ))}
            </tbody>
        </Table>
    );
});

StatisticsTable.propTypes = propTypes;
StatisticsTable.defaultProps = defaultProps;

export default StatisticsTable;
