import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    className: PropTypes.string
};

const defaultProps = {
    className: ''
};

const Logotype = React.memo(props => {
    const { className } = props;
    return (
        <div className={`navbar-brand ${className}`}>
            <img src="/img/blocket-fordon-logo-white.svg" className="navbar-brand-logo" alt="Blocket Fordon Logotype" />
        </div>
    );
});

Logotype.defaultProps = defaultProps;
Logotype.propTypes = propTypes;

export default Logotype;
