import format from 'date-fns/format';
import subMonths from 'date-fns/sub_months';
import startOfMonth from 'date-fns/start_of_month';
import startOfDay from 'date-fns/start_of_day';
import endOfMonth from 'date-fns/end_of_month';

const dateFormat = 'YYYY-MM-DD';

export const createDateRanges = (base = new Date()) => {
    const currentMonth = subMonths(base, 1);
    const comparisonMonth = subMonths(base, 2);

    return {
        current: {
            start: format(startOfMonth(currentMonth), dateFormat),
            stop: format(startOfDay(endOfMonth(currentMonth)), dateFormat)
        },
        comparison: {
            start: format(startOfMonth(comparisonMonth), dateFormat),
            stop: format(startOfDay(endOfMonth(comparisonMonth)), dateFormat)
        }
    };
};

export const summaryReducer = key => (acc, lead) => {
    acc[lead.source] = acc[lead.source] || {};
    acc[lead.source][key] = acc[lead.source][key]
        ? {
              phoneContacts: acc[lead.source][key].phoneContacts + lead.phone_contacts,
              messages: acc[lead.source][key].messages + lead.messages,
              views: acc[lead.source][key].views + lead.views
          }
        : {
              phoneContacts: lead.phone_contacts,
              messages: lead.messages,
              views: lead.views
          };
    return acc;
};

const listReducer = key => (acc, lead) => {
    acc[lead.dealer.id] = acc[lead.dealer.id] ? acc[lead.dealer.id] : lead.dealer;
    acc[lead.dealer.id][key] = acc[lead.dealer.id][key] || {};
    acc[lead.dealer.id][key][lead.source] = {
        ...lead
    };

    return acc;
};

export const composeSummary = data =>
    data && data.current
        ? data.current.leads.reduce(
              summaryReducer('current'),
              data.comparison.leads.reduce(summaryReducer('comparison'), {})
          )
        : undefined;

export const composeDealers = data =>
    data && data.current
        ? data.current.leads.reduce(listReducer('current'), data.comparison.leads.reduce(listReducer('comparison'), {}))
        : undefined;
