import React from 'react';
import Helmet from 'react-helmet-async';
import NotFound from './components/NotFound';

const Route = React.memo(() => (
    <React.Fragment>
        <Helmet>
            <title>Sidan kunde inte visas</title>
        </Helmet>
        <NotFound />
    </React.Fragment>
));

export default Route;
