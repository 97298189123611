import PropTypes from 'prop-types';

const formError = PropTypes.shape({
    status: PropTypes.bool.isRequired,
    type: PropTypes.string
});

export default {
    formError
};
