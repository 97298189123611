import PropTypes from 'prop-types';

const meta = PropTypes.shape({
    error: PropTypes.string,
    warning: PropTypes.string,
    submitFailed: PropTypes.bool
});

const option = PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
});

export default {
    meta,
    option
};
