import React from 'react';
import PropTypes from 'prop-types';
import { CardBody } from '@bytbil/bootstrap-react';
import { statisticsTypes } from 'core/types';
import svLocale from 'date-fns/locale/sv';
import format from 'date-fns/format';

const propTypes = {
    title: PropTypes.string,
    period: statisticsTypes.period,
    className: PropTypes.string
};

const defaultProps = {
    period: undefined,
    title: 'Annonsvisningar och konverteringar',
    className: undefined
};

const PeriodSummary = React.memo(props => {
    const { period, title, className } = props;

    const classNames = ['stats-block-heading', className].filter(c => !!c).join(' ');

    return (
        <CardBody className={classNames}>
            <div className="d-flex flex-column flex-lg-row justify-content-lg-between">
                <h4 className="fs-4 fs-md-5 mb-lg-0 lh-1-3">{title}</h4>
                {period && (
                    <React.Fragment>
                        <span className="font-weight-bold mr-4 d-block d-md-inline summary--current-date">
                            {[period.current.start, period.current.stop]
                                .map(periodItem => format(periodItem, 'D MMM', { locale: svLocale }))
                                .join(' - ')}
                        </span>
                        <span className="text-muted d-block d-md-inline summary--comparison-date">
                            (jämfört med{' '}
                            {[period.comparison.start, period.comparison.stop]
                                .map(periodItem => format(periodItem, 'D MMM', { locale: svLocale }))
                                .join(' - ')}
                            )
                        </span>
                    </React.Fragment>
                )}
            </div>
        </CardBody>
    );
});

PeriodSummary.defaultProps = defaultProps;
PeriodSummary.propTypes = propTypes;

export default PeriodSummary;
