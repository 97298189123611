import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Alert } from '@bytbil/bootstrap-react';
import { Loader } from '@bytbil/general-react';
import PeriodSummary from 'components/PeriodSummary';
import StatisticsSummary from 'components/StatisticsSummary';
import { statisticsTypes } from '@bytbil/api-types';
import { statisticsTypes as statisticsTypesOverrides } from 'core/types';

const propTypes = {
    summary: statisticsTypes.summary,
    period: statisticsTypesOverrides.period,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired
};

const defaultProps = {
    summary: undefined,
    period: undefined
};

const StatisticsOverview = React.memo(props => {
    const { summary, period, loading, error } = props;

    return (
        <Card className="card-container-card stats-block mt-3">
            <PeriodSummary
                title="Annonsvisningar och konverteringar för alla anläggningar"
                summary={summary}
                period={period}
                loading={loading}
            />
            {error && (
                <CardBody>
                    <Alert color="danger">Kunde inte hämta statistiken, försök igen om en stund.</Alert>
                </CardBody>
            )}
            {loading && (
                <CardBody className="text-center">
                    <Loader />
                </CardBody>
            )}
            {summary && <StatisticsSummary summary={summary} loading={loading} />}
            {!error && !loading && !summary && (
                <CardBody>
                    <Alert color="warning">Ingen data för perioden.</Alert>
                </CardBody>
            )}
        </Card>
    );
});

StatisticsOverview.propTypes = propTypes;
StatisticsOverview.defaultProps = defaultProps;

export default StatisticsOverview;
