import React from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import jwtDecode from 'jwt-decode';
import produce from 'immer';

import { createDateRanges, composeDealers, composeSummary } from './utils';

import Dashboard from './components/Dashboard';
import NoTokenError from './components/NoTokenError';

import marketplaceStatisticsQuery from './queries/marketplaceStatistics';

const propTypes = {
    token: PropTypes.string
};

const defaultProps = {
    token: undefined
};

const sortFunction = (current, comparison) => {
    const dealerNameA = current.name.toUpperCase();
    const dealerNameB = comparison.name.toUpperCase();

    if (dealerNameA < dealerNameB) {
        return -1;
    }

    if (dealerNameA > dealerNameB) {
        return 1;
    }

    return 0;
};

class StatisticsContainer extends React.PureComponent {
    render() {
        const { token } = this.props;

        if (!token) {
            return <NoTokenError />;
        }

        const tokenPayload = jwtDecode(token);

        let current;
        let comparison;
        if (tokenPayload.scopes['get.statistics.daterange']) {
            ({ current, comparison } = JSON.parse(tokenPayload.scopes['get.statistics.daterange']));
        } else {
            ({ current, comparison } = createDateRanges());
        }

        return (
            <Query query={marketplaceStatisticsQuery} variables={{ token, current, comparison }} skip={!token}>
                {({ data, error, loading }) => {
                    const list = composeDealers(data);
                    const objectList = Object.keys(list).map(id => list[id]);
                    const sortedList = produce(objectList, s => {
                        s.sort(sortFunction);
                    });

                    return data && data.current && data.comparison ? (
                        <Dashboard
                            period={{
                                current: data.current.period,
                                comparison: data.comparison.period
                            }}
                            summary={{
                                data: composeSummary(data),
                                error,
                                loading
                            }}
                            statistics={{
                                data: sortedList,
                                error,
                                loading
                            }}
                        />
                    ) : (
                        <div>loading</div>
                    );
                }}
            </Query>
        );
    }
}

StatisticsContainer.propTypes = propTypes;
StatisticsContainer.defaultProps = defaultProps;

export default StatisticsContainer;
