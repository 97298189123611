import React from 'react';
import PropTypes from 'prop-types';
import { Router } from '@reach/router';
import Helmet from 'react-helmet-async';
import Home from 'routes/home';
import NotFound from 'routes/notFound';

const propTypes = {
    token: PropTypes.string
};

const defaultProps = {
    token: undefined
};

const App = React.memo(({ token }) => (
    <React.Fragment>
        <Helmet>
            <title>Blocket månadsrapport</title>
        </Helmet>
        <Router>
            <NotFound default />
            <Home path="/" token={token} />
        </Router>
    </React.Fragment>
));

App.propTypes = propTypes;
App.defaultProps = defaultProps;

export default App;
