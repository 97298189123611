import React from 'react';
import PropTypes from 'prop-types';
import { getPercentage } from 'components/SummaryBlock/SummaryBlock';

const propTypes = {
    current: PropTypes.number,
    comparison: PropTypes.number
};

const defaultProps = {
    current: undefined,
    comparison: undefined
};

const renderStatistic = (current, comparison) => {
    const trend = getPercentage(current, comparison);

    if (typeof trend === 'undefined') {
        return <span>-</span>;
    }

    if (current > comparison) {
        return <span className="text-success">{trend}%</span>;
    }
    if (current < comparison) {
        return <span className="text-danger">{trend}%</span>;
    }

    return <span>{trend}%</span>;
};

const StatisticColumn = React.memo(props => {
    const { current, comparison } = props;

    return typeof current !== 'undefined' && comparison !== 'undefined' ? (
        <React.Fragment>{renderStatistic(current, comparison)}</React.Fragment>
    ) : (
        <React.Fragment>
            <span>-</span>
        </React.Fragment>
    );
});

StatisticColumn.propTypes = propTypes;
StatisticColumn.defaultProps = defaultProps;

export default StatisticColumn;
