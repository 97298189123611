import React from 'react';
import PropTypes from 'prop-types';
import { statisticsTypes } from '@bytbil/api-types';
import SummaryBlock from 'components/SummaryBlock';

const propTypes = {
    loading: PropTypes.bool.isRequired,
    summary: PropTypes.shape({
        blocket: statisticsTypes.summaryItem,
        bytbil: statisticsTypes.summaryItem
    })
};

const defaultProps = {
    summary: undefined
};

const StatisticsSummary = React.memo(props => {
    const { summary, loading } = props;

    const defaultCardClasses = 'stats-block-item';

    return (
        <div className="stats-block-main">
            <div className={defaultCardClasses}>
                <SummaryBlock title="Annonsvisningar" icon="eye" summary={summary} category="views" loading={loading} />
            </div>
            <div className={defaultCardClasses}>
                <SummaryBlock title="Epost/SMS" icon="mail" summary={summary} category="messages" loading={loading} />
            </div>
            <div className={defaultCardClasses}>
                <SummaryBlock
                    title="Klick på tel.nr"
                    icon="phone"
                    summary={summary}
                    category="phoneContacts"
                    loading={loading}
                />
            </div>
        </div>
    );
});

StatisticsSummary.defaultProps = defaultProps;
StatisticsSummary.propTypes = propTypes;

export default StatisticsSummary;
