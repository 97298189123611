import PropTypes from 'prop-types';

const stringOrDate = PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.instanceOf(Date)]);

/* eslint-disable import/prefer-default-export */
export const period = PropTypes.shape({
    current: PropTypes.shape({
        start: stringOrDate.isRequired,
        stop: stringOrDate.isRequired
    }),
    comparison: PropTypes.shape({
        start: stringOrDate.isRequired,
        stop: stringOrDate.isRequired
    })
});

export default {
    period
};
/* eslint-enable import/prefer-default-export */
