import React from 'react';
import PropTypes from 'prop-types';
import { Navbar } from '@bytbil/bootstrap-react';
import Layout from 'components/Layout';
import Logotype from 'components/Logotype';
import StatisticsDashBoard from 'features/statistics';

const propTypes = {
    token: PropTypes.string
};

const defaultProps = {
    token: undefined
};

const Home = React.memo(({ token }) => (
    <Layout>
        <Navbar expand="md" tag="header" className="bg-dark-alt header-main d-flex" dark>
            <div className="container d-flex justify-content-between">
                <Logotype />
            </div>
        </Navbar>
        <StatisticsDashBoard token={token} />
    </Layout>
));

Home.propTypes = propTypes;
Home.defaultProps = defaultProps;

export default Home;
