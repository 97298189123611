import React from 'react';

const NotFound = React.memo(() => (
    <main className="mt-4 pt-2 pt-md-4 text-center">
        <div className="container">
            <h1 className="page-title mb-3">Sidan kunde inte visas!</h1>
        </div>
        <div className="container card-container">
            <p>
                Felet kan bero på att du skrivit in en felaktig adress i webbläsaren eller att sidan inte längre finns.
            </p>
        </div>
    </main>
));

export default NotFound;
