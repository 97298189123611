import { ApolloClient } from 'apollo-client';
import { from } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { createHttpLink } from 'apollo-link-http';
import apolloLogger from 'apollo-link-logger';
import createCache from './createCache';

const httpLink = createHttpLink({
    uri: `/graphql`,
    credentials: 'include',
    fetch: window.fetch
});

const link = from([
    onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors)
            graphQLErrors.map(({ message, locations, path }) =>
                console.warn(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
            );
        if (networkError) console.warn(`[Network error]: ${networkError}`);
    }),
    ...(window.App.config.IS_DEV ? [apolloLogger] : []),
    httpLink
]);

const cache = createCache();

export default function createApolloClient() {
    return new ApolloClient({
        link,
        cache: cache.restore(window.App.apolloData),
        queryDeduplication: true,
        connectToDevTools: true
    });
}
