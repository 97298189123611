import React from 'react';
import PropTypes from 'prop-types';
import Intl from 'intl';
import 'intl/locale-data/jsonp/sv-SE';
import { CardBody } from '@bytbil/bootstrap-react';
import { Loader } from '@bytbil/general-react';
import { ColorScale, PieAutoScale } from '@bytbil/visualization';
import { statisticsTypes } from '@bytbil/api-types';

const propTypes = {
    loading: PropTypes.bool,
    title: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    summary: PropTypes.shape({
        blocket: statisticsTypes.summaryItem,
        bytbil: statisticsTypes.summaryItem
    }).isRequired,
    category: PropTypes.string.isRequired
};

const defaultProps = {
    loading: false
};

const numberFormat = new Intl.NumberFormat('sv-SE', { style: 'decimal' });

const maybeGet = (obj, prop) => {
    if (typeof obj !== 'undefined') {
        return obj[prop];
    }

    return undefined;
};

export const getTotal = (sources, category) => {
    const reducer = resolver => (sum, source) => {
        if (typeof resolver(source) !== 'undefined') {
            if (typeof sum !== 'undefined') {
                return sum + resolver(source)[category];
            }

            return resolver(source)[category];
        }

        return sum;
    };

    return {
        current: sources.reduce(
            reducer(source => (typeof source !== 'undefined' ? source.current : undefined)),
            undefined
        ),
        comparison: sources.reduce(
            reducer(source => (typeof source !== 'undefined' ? source.comparison : undefined)),
            undefined
        )
    };
};

// Get different badge classes based on whether the percentage is a minus, zero or plus value.
const getPercentageStatus = (current, comparison) => {
    if (current < comparison) {
        return 'danger';
    }

    if (current > comparison) {
        return 'success';
    }

    return 'default';
};

// Return a properly formatted percentage string
export const getPercentage = (currentNum, comparisonNum) => {
    if (typeof currentNum !== 'undefined' && typeof comparisonNum !== 'undefined') {
        const percentage =
            currentNum === 0 && comparisonNum === 0 ? 0 : ((currentNum - comparisonNum) / comparisonNum) * 100;

        const rounded = Math.round(percentage * 10) / 10;

        let newValue = '';

        if (!Number.isNaN(rounded) && Number.isFinite(rounded)) {
            if (rounded > 0) {
                newValue = `+ ${rounded.toString()}`;
            } else {
                newValue = rounded.toString().replace('-', '- ');
            }
        } else {
            newValue = undefined;
        }
        return newValue;
    }

    return undefined;
};

const numberOrPlaceholder = number => {
    if (typeof number !== 'undefined') {
        // Adds thousands separator to numbers

        return numberFormat.format(number);
    }

    return ' - ';
};

const SummaryBlock = props => {
    const { title, icon, summary, category, loading } = props;

    const blocketData = summary.blocket || {};
    const bytbilData = summary.bytbil || {};

    // Get total number of impressions from both sites
    const total = getTotal([blocketData, bytbilData], category);

    const viewData = {
        trend: getPercentage(total.current, total.comparison),
        blocket: {
            current: maybeGet(blocketData.current, category),
            comparison: maybeGet(blocketData.comparison, category)
        },
        bytbil: {
            current: maybeGet(bytbilData.current, category),
            comparison: maybeGet(bytbilData.comparison, category)
        },
        total: {
            current: total.current,
            comparison: total.comparison
        }
    };

    const data = [];

    if (viewData.blocket.current) {
        data.push({
            value: viewData.blocket.current,
            color: ColorScale[0]
        });
    }

    if (viewData.bytbil.current) {
        data.push({
            value: viewData.bytbil.current,
            color: ColorScale[1]
        });
    }

    return typeof summary !== 'undefined' ? (
        <React.Fragment>
            <CardBody className="py-4 text-center">
                <h4 className="mb-3 fs-5 stats-title">
                    <img src={`/img/statistics/${icon}.svg`} alt={icon} className="mr-2 align-text-center" />
                    {title}
                </h4>
                <div style={{ whiteSpace: 'nowrap' }}>
                    <span className="stats-number">
                        {loading ? <Loader className="fs-3" /> : numberOrPlaceholder(viewData.total.current)}
                    </span>
                    {typeof viewData.trend !== 'undefined' && (
                        <span
                            className={`badge badge-${getPercentageStatus(
                                viewData.total.current,
                                viewData.total.comparison
                            )} ml-2`}
                        >
                            {viewData.trend}%
                        </span>
                    )}
                </div>
                {typeof viewData.total.comparison !== 'undefined' ? (
                    <span className="text-muted">
                        ({numberOrPlaceholder(viewData.total.comparison)} föregående period)
                    </span>
                ) : (
                    <span className="text-muted">-</span>
                )}
            </CardBody>
            <CardBody className="p-0 border-top-0">
                <div className="row no-gutters mb-3">
                    <div className="col-6">
                        <div className="pie-block">
                            <PieAutoScale data={data} />
                        </div>
                    </div>
                    <div className="col-6 my-3 p-0 lh-1-4">
                        <CardBody className="px-2 pt-0 pb-2">
                            <span className="stats-legend-1" />
                            <div className="d-inline-block">
                                Blocket
                                <br />
                                {<strong>{numberOrPlaceholder(viewData.blocket.current)}</strong>}{' '}
                                <small className="text-muted">
                                    ({numberOrPlaceholder(viewData.blocket.comparison)})
                                </small>
                            </div>
                        </CardBody>
                        <CardBody className="px-2 pt-2 pb-0 border-0">
                            <span className="stats-legend-2" />
                            <div className="d-inline-block">
                                Bytbil.com
                                <br />
                                {<strong>{numberOrPlaceholder(viewData.bytbil.current)}</strong>}{' '}
                                <small className="text-muted">
                                    ({numberOrPlaceholder(viewData.bytbil.comparison)})
                                </small>
                            </div>
                        </CardBody>
                    </div>
                </div>
            </CardBody>
        </React.Fragment>
    ) : (
        <div />
    );
};

SummaryBlock.propTypes = propTypes;
SummaryBlock.defaultProps = defaultProps;

export default SummaryBlock;
