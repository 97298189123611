import React from 'react';
import { Alert } from '@bytbil/bootstrap-react';

const NoTokenError = React.memo(() => (
    <div className="container pt-5">
        <Alert color="danger">Något gick fel. Prova att klicka på länken i mailet igen.</Alert>
    </div>
));

export default NoTokenError;
