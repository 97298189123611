import gql from 'graphql-tag';

export default gql`
    fragment statisticsFields on MarketplaceStatistic {
        period {
            start
            stop
        }
        leads {
            source
            views
            phone_contacts
            messages
            dealer {
                id
                name
                city {
                    name
                }
            }
        }
    }

    query marketPlaceStatistics($token: String!, $current: PeriodInput!, $comparison: PeriodInput!) {
        current: marketPlaceStatistics(input: { token: $token, period: $current }) {
            ...statisticsFields
        }

        comparison: marketPlaceStatistics(input: { token: $token, period: $comparison }) {
            ...statisticsFields
        }
    }
`;
