import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Card, CardBody } from '@bytbil/bootstrap-react';
import { Loader } from '@bytbil/general-react';
import PeriodSummary from 'components/PeriodSummary';
import StatisticsOverview from '../StatisticsOverview';
import StatisticsTable from '../StatisticsTable';

const requestType = {
    loading: PropTypes.bool,
    error: PropTypes.bool,
    data: PropTypes.object
};

const propTypes = {
    onTableHeadClick: PropTypes.func,
    summary: PropTypes.shape(requestType).isRequired,
    period: PropTypes.object.isRequired,
    statistics: PropTypes.shape({ ...requestType }).isRequired,
    error: PropTypes.bool
};

const defaultProps = {
    error: false,
    onTableHeadClick: Function.prototype
};

const Dashboard = React.memo(props => {
    const { summary, statistics, onTableHeadClick, error, period } = props;

    return (
        <div className="feature-statistics container pt-5">
            <div className="d-sm-flex d-block">
                <div className="flex-grow mr-3">
                    <h1>Blockets månadsrapport</h1>
                </div>
                <div className="mb-3 mb-sm-0 d-sm-flex align-items-center text-right">
                    <img className="mr-3" src="/img/blocket-logo-simple.svg" height="16" width="72" alt="blocket" />
                    <img src="/img/bytbil-logo.svg" height="32" width="52" alt="bytbil" />
                </div>
            </div>
            <StatisticsOverview summary={summary.data} period={period} loading={summary.loading} error={error} />
            <Card className="card-container-card stats-block mt-3 mb-5">
                <PeriodSummary
                    title="Annonsvisningar och konverteringar fördelat per anläggning"
                    summary={statistics.data}
                    period={period}
                    loading={statistics.loading}
                />
                {!statistics.data && !statistics.error && (
                    <CardBody className="text-center">
                        <Loader />
                    </CardBody>
                )}
                {statistics.data && (
                    <CardBody>
                        <StatisticsTable
                            statistics={statistics.data}
                            period={statistics.period}
                            onTableHeadClick={onTableHeadClick}
                        />
                    </CardBody>
                )}
                {error && (
                    <CardBody>
                        <Alert color="danger">Kunde inte hämta statistiken, försök igen om en stund.</Alert>
                    </CardBody>
                )}
            </Card>
        </div>
    );
});

Dashboard.propTypes = propTypes;
Dashboard.defaultProps = defaultProps;

export default Dashboard;
