import PropTypes from 'prop-types';

const to = PropTypes.string.isRequired;
const id = PropTypes.string.isRequired;
const text = PropTypes.string.isRequired;
const icon = PropTypes.string;
const active = PropTypes.bool;
const legacy = PropTypes.bool;
const onClick = PropTypes.func;
const external = PropTypes.bool;
const subItems = PropTypes.array;

const navitem = PropTypes.shape({
    to: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    icon: PropTypes.string,
    active: PropTypes.bool,
    legacy: PropTypes.bool,
    onClick: PropTypes.func,
    external: PropTypes.bool,
    subItems: PropTypes.arrayOf(
        PropTypes.shape({
            to: PropTypes.string.isRequired,
            id: PropTypes.string.isRequired,
            text: PropTypes.string.isRequired,
            icon: PropTypes.string,
            active: PropTypes.bool,
            legacy: PropTypes.bool,
            onClick: PropTypes.func,
            external: PropTypes.bool
        })
    ),
    isNew: PropTypes.bool
});

export default {
    navitem,
    to,
    id,
    text,
    icon,
    active,
    legacy,
    onClick,
    external,
    subItems
};
